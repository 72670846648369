'use client'

import { useState, useEffect, useCallback, useTransition } from 'react'
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Search, Loader2 } from "lucide-react"
import { useRouter } from 'next/navigation'
import { useTranslations } from 'next-intl'

export function SearchBox() {
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isPending, startTransition] = useTransition()
  const router = useRouter()
  const t = useTranslations('SearchComponent')

  const handleSearch = useCallback(async (searchTerm: string = query) => {
    if (!searchTerm.trim() || isLoading) return
    
    startTransition(() => {
      setIsLoading(true)
    })
    
    try {
      let cleanedTerm = searchTerm.toLowerCase().trim()
      cleanedTerm = cleanedTerm.replace(/\s*(meaning\s*)+$/i, '').trim()
      
      const formattedTerm = cleanedTerm.replace(/\s+/g, '-')
      const url = `/meaning/${formattedTerm}-meaning`
      
      router.prefetch(url)
      await router.push(url)
    } catch (error) {
      console.error('Search error:', error)
    } finally {
      startTransition(() => {
        setIsLoading(false)
      })
    }
  }, [query, isLoading, router])

  const popularSearches = [
    "happiness", "success", "love", "peace", "wisdom"
  ]

  useEffect(() => {
    popularSearches.forEach(term => {
      const url = `/meaning/${term.toLowerCase()}-meaning`
      router.prefetch(url)
    })
  }, [])

  return (
    <div className="w-full max-w-3xl mx-auto space-y-8">
      <div className="text-center space-y-4">
        <h1 className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Search Meanings
        </h1>
        {/* <p className="text-lg text-muted-foreground">
          {t('searchPlaceholder')}
        </p> */}
      </div>

      <div className="relative">
        <form 
          onSubmit={(e) => {
            e.preventDefault()
            handleSearch()
          }} 
          className="relative"
        >
          <Input
            type="text"
            placeholder={t('searchPlaceholder')}
            className="w-full h-12 pl-4 pr-12 text-lg shadow-sm"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            disabled={isLoading}
          />
          <Button 
            type="submit"
            size="sm"
            className="absolute right-2 top-1/2 -translate-y-1/2 h-8 w-8 p-0"
            disabled={isLoading || !query.trim()}
            aria-label={t('searchButton')}
          >
            {isLoading ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : (
              <Search className="h-5 w-5" />
            )}
          </Button>
        </form>
      </div>

      <div className="flex flex-wrap gap-2 justify-center">
        {popularSearches.map((term) => (
          <Button
            key={term}
            variant="outline"
            size="sm"
            className="hover:bg-blue-50"
            onClick={() => handleSearch(term)}
            disabled={isLoading}
          >
            {term}
          </Button>
        ))}
      </div>
    </div>
  )
}